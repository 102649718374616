<template>
  <div>
    <kandidat-menu
      :kandidat="kandidatData"
      :active-item="activeItem"
    />
    <b-card no-body>
      <b-card-body>
        {{ $t('UkupanDug') }}: {{ ukupnoDug }}
        <div class="row justify-content-between">
          <div class="col-6">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-novi-zapis
              variant="success"
              size="sm"
              @click="noviZapis('razduzenje')"
            >
              {{ $t('NoviRacun') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-toggle.sidebar-right
              variant="danger"
              size="sm"
              :disabled="selected.length === 0"
            >
              {{ $t('PoveziRacune') }}
            </b-button>
          </div>
          <div
            class="col-3"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-info"
              size="sm"
              @click="pokupiListu()"
            >
              {{ $t('SviRacuni') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-warning"
              size="sm"
              @click="pokupiListu('avansi')"
            >
              {{ $t('SamoAvansi') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-info"
              size="sm"
              @click="pokupiListu()"
            >
              {{ $t('NepovezaniAvansi') }}
            </b-button>
          </div>
        </div>

      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body :title="$t('FiskalizovaniRacuni')">
        <div>
          <b-table
            :items="items"
            :fields="fields"
            selectable
            select-mode="multi"
            striped
            responsive
            class="mb-0"
            @row-selected="onRowSelected"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              >
                {{ row.detailsShowing ? $t("Sakrij") : $t("Prikazi") }}
              </b-form-checkbox>
            </template>
            <template #cell(id)>
              <b-button
                variant="primary"
                size="sm"
                @click="print()"
              >
                {{ $t("Stampaj") }}
              </b-button>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                >
                  {{ $t("SakrijDetalje") }}
                </b-button>
              </b-card>
            </template>

            <template #cell(transactionType)="data">
              {{ $t(data.item.transactionType) }}
            </template>
            <template #cell(invoiceType)="data">
              {{ $t(data.item.invoiceType) }}
            </template>
            <template
              v-slot:custom-foot
            >
              <tr
                class="p-3 mb-2 bg-success text-dark"
              >
                <td
                  colspan="3"
                >
                  &nbsp;
                </td>
                <td
                  class="text-right"
                >
                  {{ ukupnoZaduzenje }}
                </td>
                <td
                  class="text-right"
                >
                  {{ ukupnoRazduzenje }}
                </td>
                <td
                  colspan="2"
                >
                  &nbsp;
                </td>
              </tr>
            </template>
          </b-table>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-novi-zapis"
      ref="modal-novi-zapis"
      cancel-variant="outline-secondary"
      :ok-title="$t('Unesi')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="modalZaduzenjeTitle"
      size="lg"
      @ok="snimiNoviZapis"
    >
      <b-form>
        <b-form-group>
          <label for="datum_novog_zapisa">{{ $t('Datum') }}:</label>
          <b-form-datepicker
            id="datum_novog_zapisa"
            v-model="dataNoviZapis.datum"
            :placeholder="$t('Datum')"
            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
            :locale="this.$i18n.locale"
            :hide-header="hideHeader"
            today-button
            reset-button
            close-button
            show-decade-nav
            :label-today-button="$t('Danas')"
            :label-reset-button="$t('Ponisti')"
            :label-close-button="$t('Zatvori')"
            :label-prev-year="$t('PrethodnaGodina')"
            :label-prev-month="$t('PrethodniMesec')"
            :label-next-year="$t('SledecaGodina')"
            :label-next-month="$t('SledeciMesec')"
            :label-current-month="$t('TekuciMesec')"
            :label-prev-decade="$t('MinusDesetGodina')"
            :label-next-decade="$t('PlusDesetGodina')"
            label-help=""
            no-flip
          />
          <small
            v-if="formErrors['datum']"
            class="text-danger"
          >
            {{ $t(formErrors['datum'][0]) }}
          </small>
        </b-form-group>
        <b-form-group
          :label="$t('TipRacuna')"
        >
          <b-button-group
            size="sm"
          >
            <b-button
              v-for="(tip, index) in tipoviRacuna"
              :key="index"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="tipRacuna === tip.sifra ? 'primary' : 'outline-primary'"
              @click="izaberiTipRacuna(tip.sifra)"
            >
              {{ tip.naziv }}
            </b-button>
          </b-button-group>
        </b-form-group>
        <b-form-group>
          <label for="artikal">{{ $t('Artikal') }}</label>
          <v-select
            v-model="dataNoviZapis.artikal"
            :placeholder="$t('Artikal')"
            :options="artikli"
            :clearable="false"
            input-id="artikal"
          />
        </b-form-group>
        <b-form-group
          label="poreska_stopa"
          label-for="poreska_stopa"
        >
          <v-select
            v-model="dataNoviZapis.poreska_stopa"
            :placeholder="$t('PoreskaStopa')"
            :options="poreskeStope"
            :clearable="false"
            input-id="poreska_stopa"
          />
        </b-form-group>
        <b-form-group>
          <label for="opis_novog_zapisa">{{ $t('Opis') }}</label>
          <b-form-input
            id="opis_novog_zapisa"
            v-model="dataNoviZapis.opis"
            :placeholder="$t('Opis')"
          />
          <small
            v-if="formErrors['opis']"
            class="text-danger"
          >
            {{ $t(formErrors['opis'][0]) }}
          </small>
        </b-form-group>
        <b-form-group>
          <label for="iznos_novog_zapisa">{{ $t('Iznos') }}</label>
          <b-form-input
            id="iznos_novog_zapisa"
            v-model="dataNoviZapis.iznos"
            :placeholder="$t('Iznos')"
            type="number"
          />
          <small
            v-if="formErrors['iznos']"
            class="text-danger"
          >
            {{ $t(formErrors['iznos'][0]) }}
          </small>
        </b-form-group>
        <b-form-group
          :label="$t('NacinPlacanja')"
        >
          <b-button-group
            size="sm"
          >
            <b-button
              v-for="(np, index) in naciniPlacanja"
              :key="index"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="nacinPlacanja === np.sifra ? 'primary' : 'outline-primary'"
              @click="izmeniNacinPlacanja(np.sifra)"
            >
              {{ np.naziv }}
            </b-button>
          </b-button-group>
        </b-form-group>
        <b-form-group
          :label="$t('Napomena')"
          label-for="napomena"
        >
          <b-form-textarea
            id="napomena"
            v-model="dataNoviZapis.napomena"
            :placeholder="$t('Napomena')"
            rows="3"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <div
      id="tekstRacuna"
    >
      <div
        style="white-space: pre-wrap; word-wrap: break-word; font-family: inherit;"
      >
        {{ racunZaStampu }}
      </div>
    </div>
    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      shadow
      :title="$t('ListaRacuna')"
    >
      <b-table
        v-if="selected.length > 0"
        responsive
        :items="selected"
        :fields="povezaniRacuniFields"
        :fixed="true"
        class="mb-0"
      />
      <b-alert
        v-if="selected.length === 0"
        variant="primary"
        show
      >
        <div class="alert-body">
          <span><strong>{{ $t('ListaRacuna') }}</strong> {{ $t('TrenutnoNemaOznacenihRacuna') }}</span>
        </div>
      </b-alert>
      <div class="row justify-content-center">
        <div class="col-6">
          <b-button
            v-if="selected.length > 0"
            v-b-modal.modal-potvrda-spajanja
            size="sm"
            class="mr-1"
            variant="primary"
          >
            {{ $t('Povezi') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>
    <b-modal
      id="modal-potvrda-spajanja"
      ref="modalpotvrdaspajanja"
      modal-class="modal-warning"
      centered
      :title="this.$i18n.t('PovezivanjeRacuna')"
      hide-footer
    >
      <b-card-text>
        {{ $t('DaLiSteSigurniDaZeliteDaPovezeteRacune?') }}
      </b-card-text>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-warning"
        @click="poveziRacune"
      >
        {{ $t('Povezi') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BButtonGroup,
  BCardBody,
  BCard,
  BCardText,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormTextarea,
  BSidebar,
  VBToggle,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import KandidatMenu from '../kandidati/KandidatMenu.vue'

export default {
  components: {
    BTable,
    BButton,
    BButtonGroup,
    BFormCheckbox,
    BCardBody,
    BModal,
    BCard,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTextarea,
    BSidebar,
    BAlert,
    vSelect,
    'kandidat-menu': KandidatMenu,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      activeItem: 'finansije',
      fields: [
        { key: 'detalji', label: this.$i18n.t('Detalji') },
        { key: 'transactionType', label: this.$i18n.t('TipTransakcije') },
        { key: 'invoiceType', label: this.$i18n.t('TipRacuna') },
        { key: 'iznos', label: this.$i18n.t('Iznos'), class: 'text-right' },
        { key: 'pdv', label: this.$i18n.t('PDV'), class: 'text-right' },
        { key: 'id', label: 'Akcije' },
      ],
      povezaniRacuniFields: [
        { key: 'id', label: 'ID' },
        { key: 'iznos', label: this.$i18n.t('Iznos'), class: 'text-right' },
        { key: 'pdv', label: this.$i18n.t('PDV'), class: 'text-right' },
      ],
      naciniPlacanja: [
        { naziv: this.$i18n.t('Kes'), sifra: 1 },
        { naziv: this.$i18n.t('Kartica'), sifra: 2 },
        { naziv: this.$i18n.t('Cek'), sifra: 3 },
        { naziv: this.$i18n.t('Elektronski'), sifra: 4 },
        { naziv: this.$i18n.t('Vaucer'), sifra: 5 },
        { naziv: this.$i18n.t('MobilniNovac'), sifra: 6 },
        { naziv: this.$i18n.t('Ostalo'), sifra: 0 },
      ],
      tipoviRacuna: [
        { naziv: this.$i18n.t('Normal'), sifra: 'Normal' },
        { naziv: this.$i18n.t('Avans'), sifra: 'Advance' },
      ],
      /* eslint-disable global-require */
      items: [],
      kandidatData: {},
      modalZaduzenjeTitle: '',
      hideHeader: true,
      dataNoviZapis: { datum: moment(new Date()).format('YYYY-MM-DD') },
      formErrors: [],
      notificationVariant: 'success',
      notificationTitle: this.$i18n.t('Uspesno'),
      notificationMessage: this.$i18n.t('NoviZapisJeUspesnoDodat'),
      ukupnoZaduzenje: 0,
      ukupnoRazduzenje: 0,
      ukupnoDug: 0,
      artikli: [],
      poreskaStopa: '',
      poreskeStope: ['А', 'Ђ', 'Г', 'Е'],
      nacinPlacanja: 0,
      racunZaStampu: '',
      tipRacuna: 'Normal',
      prikaziSidebar: false,
      selected: [],
    }
  },
  watch: {
    'dataNoviZapis.artikal': {
      handler(artikal) {
        this.popuniDataNoviZapisArtikal(artikal)
      },
    },
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    poveziRacune() {
      this.$bvModal.hide('modal-potvrda-spajanja')
    },
    makeToast(variant = null) {
      const toaster = 'b-toaster-bottom-center'
      this.$bvToast.toast(`${this.$i18n.t('TrenutnoJeSelektovano')} ${this.selected.length}`, {
        title: this.$i18n.t('SelektovanRacun'),
        toaster,
        variant,
        solid: true,
      })
    },
    onRowSelected(items) {
      this.selected = items
      this.makeToast('danger')
    },
    print(tekst) {
      this.racunZaStampu = tekst
      // Pass the element id here
      this.$htmlToPaper('tekstRacuna')
    },
    izmeniNacinPlacanja(nacinPlacanja) {
      this.nacinPlacanja = nacinPlacanja
    },
    izaberiTipRacuna(tip) {
      this.tipRacuna = tip
    },
    popuniDataNoviZapisArtikal(artikal) {
      this.dataNoviZapis.poreska_stopa = ''
      this.dataNoviZapis.opis_artikla = ''
      this.dataNoviZapis.iznos = ''

      if (typeof artikal !== 'undefined') {
        this.dataNoviZapis.poreska_stopa = artikal.poreska_stopa
        this.dataNoviZapis.opis = artikal.opis_artikla
        this.dataNoviZapis.iznos = artikal.cena
      }
    },
    noviZapis(tip) {
      this.formErrors = []
      this.modalZaduzenjeTitle = this.$i18n.t(tip)
      this.dataNoviZapis.vrsta = tip
      this.dataNoviZapis.kandidat_id = this.$route.params.id
    },
    snimiNoviZapis() {
      this.dataNoviZapis.paymentType = this.nacinPlacanja
      this.dataNoviZapis.invoiceType = this.tipRacuna
      this.dataNoviZapis.transactionType = 'Sale'
      this.$http.post('/autoskole-administratori/kandidat/finansije', this.dataNoviZapis)
        .then(response => {
          this.artikli = []
          this.dataNoviZapis = {}
          this.dataNoviZapis.datum = moment(new Date()).format('YYYY-MM-DD')
          this.notificationVariant = 'success'
          this.notificationTitle = this.$i18n.t('Uspesno')
          this.notificationMessage = this.$i18n.t('NoviZapisJeUspesnoDodat')
          this.print(response.data.api_journal)
          this.prikaziPoruku()
          this.pokupiListu()
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.notificationVariant = 'danger'
            this.notificationTitle = this.$i18n.t('Neuspesno')
            this.notificationMessage = this.$i18n.t('MolimoPopuniteSvaPolja')
            this.formErrors = error.response.data.errors
            this.$refs['modal-novi-zapis'].show()
            this.prikaziPoruku()
          }
        })
    },
    prikaziPoruku() {
      const variant = this.notificationVariant
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.notificationTitle,
          icon: 'InfoIcon',
          text: this.notificationMessage,
          variant,
        },
      },
      {
        position: 'top-center',
      })
    },
    pokupiListu(tip = 'svi') {
      const url = tip === 'svi' ? `/autoskole-administratori/kandidat/fiskalizovani-racuni/${this.$route.params.id}` : `/autoskole-administratori/kandidat/fiskalizovani-racuni/${this.$route.params.id}/${tip}`
      this.$http.get(url).then(response => {
        this.items = response.data.racuni
        this.kandidatData = response.data.kandidat
      })

      this.$http.get('/autoskole-administratori/sifarnici-artikala').then(response => {
        Object.entries(response.data).forEach(value => {
          this.artikli.push(
            {
              value: value[1].id,
              label: `${value[1].naziv_artikla}`,
              poreska_stopa: `${value[1].poreska_stopa}`,
              opis_artikla: `${value[1].opis_artikla}`,
              cena: `${value[1].cena}`,
            },
          )
        })
      })
    },
    priznanica(id) {
      this.$http.get(`/autoskole-administratori/kandidat/finansije/priznanica/${id}`, { responseType: 'arraybuffer' }).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Priznanica.pdf'
        link.click()
        this.$vs.loading.close()
      })
    },
  },
}
</script>
